<template>
  <div>
    <van-empty description="404" />
  </div>
</template>
<script>
export default {
  setup() {
      
  },
}
</script>