function formatNum(num) {
    // (\+|-)? 表示 正负号； (\d+) 表示整数部分； (\.\d+)? 表示小数部分
    if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(num)) { 
        // 不满足规则说明传递来的数据不是一个正常数字的格式
       return num; 
    }
    // 分别是正负号、整数部分、小数部分。
    var a = RegExp.$1, b = RegExp.$2, c = RegExp.$3;
    // 用来匹配出整数部分 首次出现连续四个数字加一个逗号 或者 结尾连续四个数字 的形式；
    var re =/(\d)(\d{3})(,|$)/;

    // 从左往右匹配 当遇到第一个逗号就停止，或者没有逗号一直到结尾停止。这样拿到逗号（结尾）前四个数字
    while (re.test(b)) {
        // 将匹配成功的四个数字，替换成一个数字加逗号加三个数字的形式
        // $1 表示正则表达式中的第一个子表达式，也就是 (\d) ，它表示匹配到的四个数字中的第一个数字
        // $2 也就是 (\d{3}) ， 表示匹配到的 后面三个数字
        // $3 也就是 (,|$) ， 表示匹配到的四个数字后面的 逗号或者空（结尾就是空）
        // replace方法是不影响原字符串的，它返回一个替换好的新字符串，所以这里要将替换好的覆盖原来的
        b = b.replace(re, "$1,$2$3");

        // 由于re不是全局的正则表达式（ 没有加参数g ），所以每次循环替换首次匹配成功的一处
        // 也就是 每次循环 拿到 整数部分第一个逗号往前数的 四个数字
        // 然后 将拿到的四个数字中 在第一个数字与后三个数字之间加上逗号
        // 直到整数部分从左往右 匹配不到 连续四个数字加逗号 的形式，循环结束
    }

    // 拼接原来的正负号、已分隔好的整数部分、原来的小数部分
    return a + "" + b + "" + c;
}
//实现一键复制文本内容，用于鼠标右键粘贴。
const number = {
    beforeMount(el, binding) {
      el.innerText = formatNum(binding.value);
    },
    // 当传进来的值更新的时候触发
    updated(el, binding) {
      el.innerText = formatNum(binding.value);
    },
  }
  
  export default number

